.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7a7b5;
  color: #50353b;
  flex-direction: column;
}

h1 {
  font-size: 32px;
  padding: 0;
  margin: 0;
}

.hcontainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 40px;
}

.hcontainer h2 {
  font-weight: 300;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  background-color: #ffffff;
  color: #50353b;
  appearance: none;
  border: none;
  outline: 0;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: semibold;
}
